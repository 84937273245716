<template>
  <v-container class="">
    <v-row
      v-if="!product.id"
      class="fill-height"
      align-content="center"
      justify="center"
      align="center"
    >
      <v-col
        v-if="!loading"
        cols="12"
      >
        <h2 class="primary--text text-center">
          {{ $t("Produit non trouvé") }}
        </h2>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-skeleton-loader
          type="image"
          height="250"
        />
        <v-skeleton-loader
          type="image"
          height="250"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-skeleton-loader
          type="article"
          height="250"
        />
        <v-skeleton-loader
          type="article"
          height="250"
        />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        cols="12"
        md="6"
        lg="6"
        xl="6"
      >
        <div class="product-slider">
          <product-media
            v-model="product"
            :height="$vuetify.breakpoint.smAndDown?($vuetify.breakpoint.xsOnly?400:400):450"
            width="300"
            thumb-size="70"
            field="large"
            :show-thumbnails="true"
            source="single"
            :title="product.name"
          />
          <!--            <VueSlickCarousel
              ref="c1"
              :fade="true"
              :asNavFor="$refs.c2"
              :focusOnSelect="true">
                <div v-for="(item, index) in flashDeals" :key="index">
                   <v-img class="mx-auto" width="300" height="auto" :src="item.img"></v-img>
                </div>

            </VueSlickCarousel>
            <VueSlickCarousel
              ref="c2"
              class="product-slider-click"
              :asNavFor="$refs.c1"
              :slidesToShow="3"
              :focusOnSelect="true">
              <div v-for="(item, index) in flashDeals" :key="index">
                <v-avatar
                    class="white rounded mr-3"
                    tile
                    size="78"

                >
                    <img :src="item.img" alt="">
                </v-avatar>
              </div>
            </VueSlickCarousel>-->
        </div>
      </v-col>
      <!-- <v-col cols="12" lg="6" align="center">
            <v-img max-width="300" src="@/assets/images/products/headphone.png"></v-img>
            <div class="d-flex justify-center mt-12">
                <v-avatar tile class="rounded-lg border-primary bg-white me-3" size="70" >
                    <img class="pa-3 " src="@/assets/images/products/headphone.png" alt="">
                </v-avatar>
                <v-avatar tile class="rounded-lg border-grey bg-white me-3" size="70" >
                    <img class="pa-3 " src="@/assets/images/products/headphone.png" alt="">
                </v-avatar>
                <v-avatar tile class="rounded-lg border-grey bg-white" size="70" >
                    <img class="pa-3 " src="@/assets/images/products/headphone.png" alt="">
                </v-avatar>
            </div>
          </v-col> -->
      <v-col
        cols="12"
        md="6"
        lg="6"
        xl="6"
      >
        <h1 class="mb-4 text-h5 text-md-h5 font-weight-bold">
          {{ product.name }}
        </h1>
        <div class="mb-4">
          <strong>{{ $t("Code de l'article") }}:
            <span class="font-italic">#{{ product.id }}</span></strong>
        </div>
        <div class="mb-4">
          <p
            class=""
            v-html="product.introduction"
          />
        </div>
        <product-attributes :product="product" />
        <div
          v-if="product.reviews && product.reviews.length > 0"
          class="d-flex mb-4"
        >
          <p class="grey--text text--grey-lighten-1 me-3 mb-0">
            {{ $t("Notations") }}:
          </p>
          <div class="d-flex align-center justify-center align-center mb-1">
            <v-rating
              :value="product.reviews_avg_rating"
              color="amber"
              dense
              half-increments
              readonly
              size="16"
            />

            <div class="grey--text text--darken-4 ml-1">
              ({{ product.reviews.length }})
            </div>
          </div>
        </div>
        <div class="mb-4">
          <div class="">
            <clock
              v-if="product.promo"
              :now="product.promo.now"
              :future="product.promo.dateend"
              :start="product.promo.datestart"
              :single="true"
            />
          </div>
          <product-price :product="product" />

          <p class="">
            <span v-if="product.quantity">{{ product.quantity }} {{ $t("Stock disponible") }}</span>
            <span v-else>{{ $t("En rupture de stock") }}</span>
            <br>
            <span v-if="product.weight">{{ $t("Poid") }}: {{ product.weight }}{{ $t("Kg") }}</span>
          </p>
        </div>
        <div class="mb-6">
          <v-row
            no-gutters
            class="d-flex"
          >
            <v-col
              cols="auto"
              md="6"
              lg="3"
              xl="3"
            >
              <add-to-cart-button
                :product="product"
                :mini="false"
              />
            </v-col>
          </v-row>
        </div>
        <div class="d-flex mb-4">
          <!--          <p class="grey&#45;&#45;text text&#45;&#45;grey-lighten-1 me-3">
            {{ $t("Vendeur") }}:
          </p>-->
          <p class="font-600">
            <vendor-link
              :name-length="100"
              :show-country="true"
              :vendor="{...product.user,
                        path: `/shops/${product.user.id || ''}`,
              }"
            />
          </p>
        </div>
        <div class="d-flex mb-4">
          <!--          <p class="grey&#45;&#45;text text&#45;&#45;grey-lighten-1 me-3">
            {{ $t("Vendeur") }}:
          </p>-->
          <p class="font-600">
            <v-btn outlined depressed color="primary" @click="openChat">{{ $t("Contacter le vendeur")}}</v-btn>
          </p>
        </div>
      </v-col>
      <v-col
        cols="12"
        class="mt-md-12"
      >
        <v-tabs
          v-model="tab"
          background-color="transparent"
          color="primary"
          show-arrows
          class=""
        >
          <v-tab class="font-600 text-capitalize">
            {{ $t("Descriptions") }}
          </v-tab>
          <v-tab class="font-600 text-capitalize">
            {{ $t("Commentaires") }} <span v-if="product.reviews && product.reviews.length > 0">({{ product.reviews.length }})</span>
          </v-tab>
          <v-tab class="font-600 text-capitalize">
            {{ $t("Information du vendeur") }}
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-model="tab"
          class="transparent my-6 pb-6"
        >
          <v-tab-item>
            <div
              class="Typography-sc-1nbqu5-0 ebXTOD"
              v-html="product.description"
            />
          </v-tab-item>
          <v-tab-item>
            <div
              v-for="review in product.reviews || []"
              class="mb-8"
            >
              <div class="d-flex flex-wrap mb-4">
                <v-avatar
                  v-if="review.user"
                  class="me-3"
                  size="48"
                >
                  <img
                    lazy-src="@/assets/images/avatars/2.png"
                    :src="review.user.avatar || '@/assets/images/avatars/2.png'"
                    :alt="review.user.name"
                  >
                </v-avatar>
                <div>
                  <h5
                    v-if="review.user"
                    class="font-600"
                  >
                    {{ review.user.pseudo || review.user.name }}
                  </h5>
                  <div class="d-flex align-center justify-center mb-1">
                    <v-rating
                      :value="review.rating"
                      color="amber"
                      class="me-2"
                      dense
                      half-increments
                      readonly
                      size="16"
                    />

                    <div class="font-600 me-2">
                      {{ review.rating }}
                    </div>
                    <div class="grey--text text--darken-2">
                      {{ review.human_created_at }}
                    </div>
                  </div>
                </div>
              </div>
              <v-row>
                <v-col
                  cols="12"
                  sm="7"
                  md="6"
                  lg="4"
                >
                  {{ review.comment }}
                </v-col>
              </v-row>
            </div>
            <product-review-form :product="product" />
          </v-tab-item>
          <v-tab-item>
            <vendor-link
              :name-length="100"
              :show-country="true"
              :vendor="{...product.user, country_code: product.user.country_code, image: product.user.avatar || '', path: `/profile/${product.user_id}`}"
            />
            <div
              class="Typography-sc-1nbqu5-0 ebXTOD"
              v-html="product.user.introduction"
            />
          </v-tab-item>
        </v-tabs-items>

        <!--        <h3 class="mb-6">
          Frequently Bought Together
        </h3>

        &lt;!&ndash; for testing  &ndash;&gt;
        <v-row class="my-6">
          <v-col
            cols="12"
            sm="6"
            lg="2"
            md="4"
          >
            <div class="d-flex align-center">
              <base-card>
                <v-card-text>
                  <router-link
                    to="/single-product"
                    class="text-decoration-none"
                  >
                    <v-img
                      contain
                      class="card-overlay-item mb-2 br-8"
                      src="@/assets/images/products/Groceries/2.PremiumGroceryCollection.png"
                    />
                    <h5 class="grey&#45;&#45;text text&#45;&#45;darken-4">
                      Premium Grocery
                    </h5>
                    <div class="d-flex">
                      <h5 class="primary&#45;&#45;text me-2">
                        $250
                      </h5>
                      <h5 class="grey&#45;&#45;text text&#45;&#45;darken-1 text-decoration-line-through">
                        $250
                      </h5>
                    </div>
                  </router-link>
                </v-card-text>
              </base-card>
              <div class="ms-1">
                <h2 class="mb-0 grey&#45;&#45;text text&#45;&#45;darken-2">
                  +
                </h2>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            lg="2"
            md="4"
          >
            <div class="d-flex align-center">
              <base-card>
                <v-card-text>
                  <router-link
                    to="/single-product"
                    class="text-decoration-none"
                  >
                    <v-img
                      contain
                      class="card-overlay-item mb-2 br-8"
                      src="@/assets/images/products/Groceries/3.PremiumGroceryPack.png"
                    />
                    <h5 class="grey&#45;&#45;text text&#45;&#45;darken-4">
                      Premium Grocery Pack
                    </h5>
                    <div class="d-flex">
                      <h5 class="primary&#45;&#45;text me-2">
                        $250
                      </h5>
                      <h5 class="grey&#45;&#45;text text&#45;&#45;darken-1 text-decoration-line-through">
                        $250
                      </h5>
                    </div>
                  </router-link>
                </v-card-text>
              </base-card>
              <div class="ms-1">
                <h2 class="mb-0 grey&#45;&#45;text text&#45;&#45;darken-2">
                  +
                </h2>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            lg="2"
            md="4"
          >
            <div class="d-flex align-center">
              <base-card>
                <v-card-text>
                  <router-link
                    to="/single-product"
                    class="text-decoration-none"
                  >
                    <v-img
                      contain
                      class="card-overlay-item mb-2 br-8"
                      src="@/assets/images/products/Groceries/4.FreashRealCholeMasala.png"
                    />
                    <h5 class="grey&#45;&#45;text text&#45;&#45;darken-4">
                      Freash Masala
                    </h5>
                    <div class="d-flex">
                      <h5 class="primary&#45;&#45;text me-2">
                        $250
                      </h5>
                      <h5 class="grey&#45;&#45;text text&#45;&#45;darken-1 text-decoration-line-through">
                        $250
                      </h5>
                    </div>
                  </router-link>
                </v-card-text>
              </base-card>
              <div class="ms-1">
                <h2 class="mb-0 grey&#45;&#45;text text&#45;&#45;darken-2">
                  +
                </h2>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            lg="2"
            md="4"
          >
            <div class="d-flex align-center">
              <base-card>
                <v-card-text>
                  <router-link
                    to="/single-product"
                    class="text-decoration-none"
                  >
                    <v-img
                      contain
                      class="card-overlay-item mb-2 br-8"
                      src="@/assets/images/products/Groceries/5.GumPack.png"
                    />
                    <h5 class="grey&#45;&#45;text text&#45;&#45;darken-4">
                      Gum Pack
                    </h5>
                    <div class="d-flex">
                      <h5 class="primary&#45;&#45;text me-2">
                        $250
                      </h5>
                      <h5 class="grey&#45;&#45;text text&#45;&#45;darken-1 text-decoration-line-through">
                        $250
                      </h5>
                    </div>
                  </router-link>
                </v-card-text>
              </base-card>
              <div class="ms-1">
                <h2 class="mb-0 grey&#45;&#45;text text&#45;&#45;darken-2">
                  =
                </h2>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            lg="4"
            md="4"
          >
            <base-card class="transparent h-100 d-flex align-center">
              <v-card-text class="d-flex flex-column align-center">
                <h3 class="primary&#45;&#45;text mb-2">
                  $2500
                </h3>
                <span class="text-14 grey&#45;&#45;text text&#45;&#45;darken-2 mb-6">Save $500</span>
                <div class="d-flex flex-wrap">
                  <v-btn
                    color="primary"
                    class="text-capitalize font-600 me-3 mb-2"
                  >
                    Add to cart
                  </v-btn>
                  <v-btn
                    color="primary"
                    outlined
                    class="text-capitalize font-600 mb-2"
                  >
                    Add to cart
                  </v-btn>
                </div>
              </v-card-text>
            </base-card>
          </v-col>
        </v-row>

        &lt;!&ndash; ending:testing  &ndash;&gt;

        <h3 class="mb-6">
          Also Available at
        </h3>
        <div class="d-flex align-center flex-wrap">
          <base-card class="text-center me-6 mb-6">
            <router-link
              to="/vendor-store"
              class="text-decoration-none"
            >
              <v-card-text class="px-12 py-8 ">
                <v-avatar
                  size="48"
                  class="mb-3"
                >
                  <img
                    src="@/assets/images/faces/propic.png"
                    alt=""
                  >
                </v-avatar>
                <h4 class="font-600 grey&#45;&#45;text text&#45;&#45;darken-3">
                  Tech Friend
                </h4>
              </v-card-text>
            </router-link>
          </base-card>
          <base-card class="text-center me-6 mb-6">
            <router-link
              to="/vendor-store"
              class="text-decoration-none"
            >
              <v-card-text class="px-12 py-8 ">
                <v-avatar
                  size="48"
                  class="mb-3"
                >
                  <img
                    src="@/assets/images/faces/propic(1).png"
                    alt=""
                  >
                </v-avatar>
                <h4 class="font-600 grey&#45;&#45;text text&#45;&#45;darken-3">
                  Smart Shop
                </h4>
              </v-card-text>
            </router-link>
          </base-card>
          <base-card class="text-center mb-6">
            <router-link
              to="/vendor-store"
              class="text-decoration-none"
            >
              <v-card-text class="px-12 py-8 ">
                <v-avatar
                  size="48"
                  class="mb-3"
                >
                  <img
                    src="@/assets/images/faces/propic.png"
                    alt=""
                  >
                </v-avatar>
                <h4 class="font-600 grey&#45;&#45;text text&#45;&#45;darken-3">
                  Gadget 360
                </h4>
              </v-card-text>
            </router-link>
          </base-card>
        </div>-->

        <products-list
          v-if="product.category && product.related_products.length"
          :products="product.related_products || []"
          :title="$t('PRODUITS CONNEXES')"
          :url="`/categories/${product.category.slug || ''}`"
        />
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar.active"
      dark
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <v-btn
        text
        @click="snackbar.active = false"
      >
        {{ $t("Close") }}
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'

  import ProductMedia from '../../components/products/ProductMedia.vue'

  import ProductsList from '../../components/products/ProductsList.vue'
  import AddToCartButton from '../../components/products/AddToCartButton.vue'
  import VendorLink from '../../components/VendorLink.vue'
  import ProductPrice from '../../components/products/ProductPrice.vue'
  import ProductAttributes from '../../components/products/ProductAttributes.vue'
  import ProductReviewForm from '../../components/products/ReviewForm.vue'
  import { i18n } from '../../plugins/i18n'
  import Clock from '../../components/Clock.vue'

  export default {
    metaInfo() {
      return {
        title: this.product.name,
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: this.product.introduction
          },
          {
            vmid: 'og:type',
            name: 'og:type',
            property: 'og:type',
            content: 'product'
          },
          {
            vmid: 'og:image:width',
            name: 'og:image:width',
            property: 'og:image:width',
            content: '300'
          },
          {
            vmid: 'image',
            name: 'og:image',
            property: 'og:image',
            itemprop:"image primaryImageOfPage",
            content: this.product.thumb
          },
          {
            vmid: 'product:pretax_price:amount',
            name: 'product:pretax_price:amount',
            property: 'product:pretax_price:amount',
            content: this.product.price_without_tax
          },
          {
            vmid: 'product:pretax_price:currency',
            name: 'product:pretax_price:currency',
            property: 'product:pretax_price:currency',
            content: 'EUR'
          },
          {
            vmid: 'product:price:currency',
            name: 'product:price:currency',
            property: 'product:price:currency',
            content: 'EUR'
          },
          {
            vmid: 'product:price:amount',
            name: 'product:price:amount',
            property: 'product:price:amount',
            content:  this.product.price_with_tax
          }

        ]
      }
    },
    components: {
      Clock,

      ProductReviewForm,
      ProductAttributes,
      AddToCartButton,
      VendorLink,
      ProductPrice,
      ProductsList,
      ProductMedia,
    },

    data () {
      return {
        tab: null,
        loading: false,
        isQuantity: 0,
        product: {},
        attributes: [],
        productattributes: [],
        snackbar: { active: false, text: '', color: 'primary' },
        breadcrumbs: [
          {
            text: i18n.t('Accueil'),
            disabled: false,
            to: '/',
          },
        ],
        /* product: {
          id: 1,
          img: require('@/assets/images/products/flash-1.png'),
          title: 'Sneakers',
          category: 'Sneakers',
          amount: 300,
          rating: 3,
          qty: 0,
        }, */
        flashDeals: [
          {
            img: require('@/assets/images/products/flash-1.png'),
            name: 'Sneakers',
          },
          {
            img: require('@/assets/images/products/flash-2.png'),
            name: 'Watch',
          },
          {
            img: require('@/assets/images/products/flash-3.png'),
            name: 'Mobile',
          },

        ],

      }
    },
    computed: {
      ...mapGetters(['getProducts']),
      ...mapState({
        user: 'user',
      }),
      pathParams () {
        return this.$route.params.slug
      },
    },
    watch: {
      pathParams (val) {
        this.product = {}
        var item = this.getProducts.find(ele => ele.slug === this.$route.params.slug)
        if (item != null) {
          this.product = item
          this.product.qty = this.$store.state.cart.products.find((el) => el.id === this.product.id).qty || 0
          this.setValue({
            type: 'breadcrumbs',
            value: [...this.breadcrumbs, ...(this.product.breadcrumbs || []), {
              text: this.product.name,
              disabled: true,
              to: '/',
            }],
          })
        }
        this.getProduct()
      },
    },
    mounted () {
      console.log("mounted")
    },
    created () {
      console.log("created")
      var item = this.getProducts.find(ele => ele.slug === this.$route.params.slug)
      if (item != null) {
        console.log("item",item)
        this.product = {...item, qty: (this.$store.state.cart.products.find((el) => el.id === item.id) || {qty: 0}).qty }

        this.setValue({
          type: 'breadcrumbs',
          value: [...this.breadcrumbs, ...(this.product.breadcrumbs || []), {
            text: this.product.name,
            disabled: true,
            to: '/',
          }],
        })
        console.log("baba", this.product)
      }
      this.getProduct()
    },

    destroyed () {
      console.log("destroyed")
      this.setValue({
        type: 'breadcrumbs',
        value: [],
      })
    },
    methods: {
      ...mapActions(['addCart', 'removeCart', 'fetchProduct']),
      ...mapMutations([
        'pushItem', // map `this.increment()` to `this.$store.commit('increment')`
        'removeItem', // map `this.increment()` to `this.$store.commit('increment')`

        // `mapMutations` also supports payloads:
        'setValue', // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
        'updateChat', // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
      ]),

      openChat () {
        if(this.user && this.user.id){
          if(this.product && this.user && this.product.user_id != this.user.id){
            this.updateChat({type: 'product', typeId: this.product.id, title: this.product.name, image: this.product.thumb,})
          }
        }else{
          this.snackbar.text = this.$t("Veuillez vous connecter pour discuter avec le vendeur.")
          this.snackbar.color = 'red'
          this.snackbar.active = true;
        }
      },
      setCategory (cat) {
        this.product.category_id = cat.id
        this.product.category = cat
        this.results = []
        // this.categorytree.push(cat);
        this.edit_category = false

        /**/
        // eslint-disable-next-line no-undef
        axios.get('/categories/datacompletion/' + cat.id + '/' + this.product.id) // ,, {params: {term: this.category_search}}
          .then((response) => {
            this.attributes = response.data.categoryAttributes
            this.productattributes = response.data.productAttributes

            this.$root.$emit('updateattribute',
                             this.product, this.attributes, this.productattributes)
          })
      },
      addCartx (data) {
        this.addCart(data)
      },
      removeCartx (data) {
        this.removeCart(data)
      },
      getProduct () {
        this.loading = true
        axios
          .get(`/products/${this.$route.params.slug}`)
          .then(response => {
            if (response.data && response.data.id) {
              var ele = this.processProduct(response.data)
              var related_products = ele.related_products || []
              this.product = {
                ...ele,
                qty: (this.$store.state.cart.products.find((el) => el.id === ele.id) || { qty: 0 }).qty || 0,
                related_products: related_products.map(el => {
                  el.qty = (this.$store.state.cart.products.find((e) => e.id === el.id) || { qty: 0 }).qty || 0
                  return this.processProduct(el)
                }),
              }
              this.pushItem({ type: 'products', field: 'id', value: this.product.id, object: this.product })
              this.setBreadcrumbs([...this.breadcrumbs, ...(this.product.breadcrumbs || []), {
                text: this.product.name,
                disabled: true,
                to: '/',
              }])
            }
          })
          .catch(error => console.log(error)).then(() => {
            this.loading = false
          })
      },

    },
  }
</script>
<style lang="scss" scoped>
    .product-slider {

        .product-slider-click {
            display: flex;
            justify-content: center;
            ::v-deep .slick-slide {
               width: 80px !important;
               margin-right: 16px;
               border: 1px solid rgb(218, 225, 231);
               border-radius: 5px;
            }
            ::v-deep .slick-current {
                border: 1px solid #D23F57;
                border-radius: 5px;
            }
        }
    }
</style>
